<template>
  <device-wrapper #default="{ desktop }">
    <h1 class="hidden">{{ $t("niceone") }}</h1>
    <desktop-home v-if="desktop"/>
    <mobile-home v-else/>
  </device-wrapper>
</template>

<script setup>
import desktopHome from "@/components/home/desktop-home/index"
import mobileHome from "@/components/home/mobile-home/index"
import {useCategoryStore} from "@/store/category";
import HomeHead from "@/head/home"

const categoryStore = useCategoryStore()
const {openPageFrom} = storeToRefs(categoryStore)

onUnmounted(() => {
  openPageFrom.value = 'home'
})

// Head schema
useHead({
  ...HomeHead()
})
</script>
